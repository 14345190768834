// GraphQL
import { gql, ErrorPolicy } from '@apollo/client';

// Cystom types
import { contentfulQueryConstants } from 'src/constants';

// Utils
import { getContentfulLocale } from 'src/utils/localeHelper';
import subDays from 'date-fns/subDays';

// Constants
const {
  stationPortalArticle,
  stationPortalCategory,
  stationPortalPlanogramCategory,
  stationPortalSubCategory,
  stationPortalSubCategory2,
  planogram,
  brand,
  classOfTrade,
  division,
  tag,
  region,
  externalLinks,
  faq,
  calendarEntry,
  articleCategories
} = contentfulQueryConstants;
const { True, False } = contentfulQueryConstants.values;

// Use this to wrap your GraphQL Queries up good.
export const q = (queryObject: any) => ({ query: queryObject, errorPolicy: 'all' as ErrorPolicy });

export const queryEventsWithFilters = (locale: string) => gql`{
  generalCalendarEntryCollection (locale: "${getContentfulLocale(locale)}") {
    items {
      entryTitle
      article {
        sys {
          id
        }
        specificBu
        brandsCollection (limit: 10) {
          items {
            brand
            mdmCode
          }
        }
        regionsCollection (limit: 13) {
          items {
            region
            mdmCode
          }
        }
        classOfTradeCollection (limit: 5) {
          items {
            classOfTrade
          }
        }
      }
      startDate
      endDate
      colour
    }
  }
}`;

/**
 * A query to get all planograms in the specified locale
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @deprecated not recommended to query all planograms as Contentful limits records returned to 100 or less
 * @select items { title, shelfSize { shelfSize }, sys { id, publishedAt } summary, planogramCategory { planogramCategory }, attachment { url }, tagsCollection { tag }, brandsCollection { brand }, regionsCollection { region }, classOfTradeCollection { classOfTrade },  }
*/
export const queryPlanograms = (locale: string) => gql`{
  stationPortalPlanogramCollection(locale: "${getContentfulLocale(locale)}") {
    items {
      title
      shelfSize {
        shelfSize
      }
      sys {
        id
        publishedAt
      }
      summary
      planogramCategory {
        planogramCategory
      }
      attachment {
        url
      }
      tagsCollection (limit: 5) {
        items {
          tag
        }
      }
      brandsCollection (limit: 10) {
        items {
          brand
        }
      }
      regionsCollection (limit: 13)  {
        items {
          region
        }
      }
      classOfTradeCollection (limit: 4)  {
        items {
          classOfTrade
        }
      }
    }
  }
}`;

/**
 * A query to get all Planograms in a specific Planogram Category in Contentful with a limit of 300 records returned
 * @author Kevin Parkinson
 * @param {string} category - the planogram category
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select items { title, shelfSize { shelfSize }, summary, planogramCategory { planogramCategory }, tagsCollection { tag }, brandsCollection { brand }, regionsCollection { region }, classOfTradeCollection { classOfTrade },  }
*/
export const queryPlanogramsByCategory = (category: string, locale: string) => gql`{
  stationPortalPlanogramCollection(locale: "${getContentfulLocale(locale)}", limit: 300, where: {
      planogramCategory: {
        planogramCategory: "${category}"
      }
    }) {
    items {
      title
      shelfSize {
        shelfSize
      }
      sys {
        id
        publishedAt
      }
      summary
      planogramCategory {
        planogramCategory
      }
      attachment {
        url
      }
      tagsCollection (limit: 5) {
        items {
          tag
        }
      }
      brandsCollection (limit: 10) {
        items {
          brand
          mdmCode
        }
      }
      regionsCollection (limit: 13)  {
        items {
          region
          mdmCode
        }
      }
      classOfTradeCollection (limit: 4)  {
        items {
          classOfTrade
        }
      }
    }
  }
}`;

/**
 * A query to get all Links from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select items { linkUrl, linkTitle, logo,title, logo,url, brandsCollection { brand }, regionsCollection { region }, classOfTradeCollection { classOfTrade },  }
*/
export const queryLinks = (locale: string) => gql`{
  generalLinkListCollection(locale: "${getContentfulLocale(locale)}") {
    items {
      linkUrl
      linkTitle
      logo {
        title
        url
      }
      brandsCollection (limit: 20) {
        items{
          brand
          mdmCode
        }
      }
      regionsCollection (limit: 20) {
        items {
          region
          mdmCode
        }
      }
      classOfTradeCollection (limit: 4) {
        items {
          classOfTrade
        }
      }
    }
  }
}`;

/**
 * A query to get all Links from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select linkUrl, linkTitle, logo,title, logo,url
*/
export const queryAllLinks = (locale: string) => gql`{
  generalLinkListCollection(locale: "${getContentfulLocale(locale)}") {
    items {
      linkUrl
      linkTitle
      logo {
        title
        url
      }
    }
  }
}`;

/**
 * A query to get all articles tagged with a specific tag from Contentful
 * @author Kevin Parkinson
 * @param {string} selectedTag - the Contentful tag name
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.publishedAt, title, summary, category, subcategory, subcategory2
*/
export const queryArticlesWithTag = (selectedTag: string, locale: string) => gql`{
  generalTagsCollection (locale: "${getContentfulLocale(locale)}", limit: 1, where: { tag: "${selectedTag}" }) {
    items{
      linkedFrom {
        stationPortalArticleCollection(limit: 500) {
          items {
            sys {
              id,
              publishedAt
            },
            title,
            summary,
            specificBu,
            category {
              category
            },
            subCategory {
              subCategory
            },
            subCategory2 {
              subCategory2
            }
          }
        }
      }
    }
  }
}`;

/**
 * A query to get all articles tagged with a specific tag from Contentful
 * @author Kevin Parkinson
 * @param {string} selectedTag - the Contentful tag name
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.publishedAt, title, summary, category, subcategory, subcategory2
*/
export const queryArticlesWithTagWithFilters = (selectedTag: string, locale: string) => gql`{
  generalTagsCollection(locale: "${getContentfulLocale(locale)}", limit: 1, where: {tag: "${selectedTag}"}) {
    items {
      linkedFrom {
        stationPortalArticleCollection(limit: 325) {
          items {
            sys {
              id
              publishedAt
            }
            title
            summary
            specificBu
            brandsCollection(limit: 10) {
              items {
                brand
                mdmCode
              }
            }
            regionsCollection(limit: 13) {
              items {
                region
                mdmCode
              }
            }
            classOfTradeCollection(limit: 5) {
              items {
                classOfTrade
              }
            }
            category {
              category
            }
            subCategory {
              subCategory
            }
            subCategory2 {
              subCategory2
            }
          }
        }
      }
    }
  }
}`;

/**
 * A query to get all Links from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select linkedFrom: generalLinkListCollection.items -> linkTitle, linkUrl, logo.url
*/
export const queryExternalLinks = (locale: string) => gql`{
  generalListGroupsCollection(locale: "${getContentfulLocale(locale)}", where: {  groupName: "${externalLinks}"}) {
   items {
    linkedFrom {
     generalLinkListCollection(limit: 50) {
      items {
       linkTitle
       linkUrl
       logo {
        url
       }
      }
     }
    }
   }
  }
}`;

// Articles
/**
 * A query to get all Critical Updates from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryCriticalUpdates = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.announcement': False,
  'fields.criticalUpdate': True,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,fields.title,fields.summary,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Admin from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryAdminArticles = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.adminOnly': True,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.tags,fields.category'
});

/**
 * A query to get all TM articles from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryTMROMArticles = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.tmromOnly': True,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.tags,fields.category'
});

/**
 * A query to get all Critical Updates excluding the current one from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryOtherCriticalUpdates = (excludeId: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.announcement': False,
  'fields.criticalUpdate': True,
  'sys.id[ne]': excludeId,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Announcements from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.hero, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryAnnouncements = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.announcement': True,
  'fields.criticalUpdate': False,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.title,fields.summary,fields.hero,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Announcements excluding the current one from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.hero, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryOtherAnnouncements = (excludeId: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.announcement': True,
  'fields.criticalUpdate': False,
  'sys.id[ne]': excludeId,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.hero,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Updates in Last 7 Day from Contentful
 * @author Kevin Parkinson
 * @param {number} daysAgo - the number of days ago from today
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryUpdatesInLastNDays = (daysAgo: number, locale: String): any => ({
  content_type: stationPortalArticle,
  // 'fields.announcement': False,
  // 'fields.criticalUpdate': False,
  'fields.showInRecentUpdates': True,
  'sys.updatedAt[gte]': subDays(new Date(), daysAgo),
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Updates in Last 7 Day from Contentful
 * @author Kevin Parkinson
 * @param {Date} startDate - start of the date range
 * @param {Date} endDate - end of the date range
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryUpdatesInDateRange = (startDate: Date, endDate: Date, locale: String): any => ({
  content_type: stationPortalArticle,
  // 'fields.announcement': False,
  // 'fields.criticalUpdate': False,
  'fields.showInRecentUpdates': True,
  'sys.updatedAt[gte]': startDate,
  'sys.updatedAt[lte]': endDate,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Updates in Last 7 Days excluding the current one from Contentful
 * @author Kevin Parkinson
 * @param {string} excludeId - the id of the update to exclude
 * @param {number} daysAgo - the number of days ago from today
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryOtherUpdatesInLastNDays = (excludeId: string, daysAgo: number, locale: String): any => ({
  content_type: stationPortalArticle,
  // 'fields.announcement': False,
  // 'fields.criticalUpdate': False,
  'fields.showInRecentUpdates': True,
  'sys.id[ne]': excludeId,
  'sys.updatedAt[gte]': subDays(new Date(), daysAgo),
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.specificBu,fields.subCategory2,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Updates in Last 7 Days excluding the current one from Contentful
 * @author Kevin Parkinson
 * @param {string} excludeId - the id of the update to exclude
 * @param {Date} startDate - start of the date range
 * @param {Date} endDate - end of the date range
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryOtherUpdatesInDateRange = (excludeId: string, startDate: Date, endDate: Date, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.announcement': False,
  'fields.criticalUpdate': False,
  'fields.showInRecentUpdates': True,
  'sys.id[ne]': excludeId,
  'sys.updatedAt[gte]': startDate,
  'sys.updatedAt[lte]': endDate,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Critical Updates from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id,fields.title,fields.summary,fields.specificBu,fields.category,fields.subCategory,fields.subCategory2,fields.tags,fields.brands,fields.regions,fields.classOfTrade
*/
export const queryCarWashArticles = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.category.sys.contentType.sys.id': stationPortalCategory,
  'fields.category.fields.category': articleCategories.carWash,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,fields.title,fields.summary,fields.specificBu,fields.category,fields.subCategory,fields.subCategory2,fields.tags,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all Critical Updates from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id,fields.title,fields.summary,fields.specificBu,fields.category,fields.tags
*/
export const querySiteSpecificArticles = (siteID: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.specificBu': siteID,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,fields.title,fields.summary,fields.specificBu,fields.category,fields.tags'
});

/**
 * A query to get all Articles in the Car Wash category
 * @author Kevin Parkinson
 * @param {string} category
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryArticlesByCategory = (category: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.category.sys.contentType.sys.id': stationPortalCategory,
  'fields.category.fields.category': category,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get all calender items with filter
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryCalendarArticlesWithFilters = (locale: String): any => ({
  content_type: calendarEntry,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'fields.entryTitle,fields.article,fields.article.fields,fields.startDate,fields.endDate,fields.colour'
});

/**
 * A query to get all calender items
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryCalendarArticles = (locale: String): any => ({
  content_type: calendarEntry,
  limit: 300,
  locale: getContentfulLocale(locale),
  select: 'fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour'
});

/**
 * A query to get all calender items
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryLatestHSEMoment = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': locale === 'en' ? articleCategories.hseMomentsEN : articleCategories.hseMomentsFR,
  // 'fields.subCategory.fields.subCategory': articleCategories.hseMomentsEN,
  order: 'sys.updatedAt',
  limit: 1,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.hero,fields.subCategory'
});

/**
 * A query to get all calendar items (ALD)
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryLatestOTRArticle = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': 'ALD',
  // 'fields.subCategory.fields.subCategory': locale === 'en' ? articleCategories.otrDealerEN : articleCategories.otrDealerFR,
  order: '-sys.updatedAt',
  // limit: 5,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.hero,fields.subCategory'
});

/**
* A query to get all calender items (CWD)
 * @author Michael Lipski
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryLatestOTRArticleCWD = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': 'Winter Cargo Distribution',
  // 'fields.subCategory.fields.subCategory': locale === 'en' ? articleCategories.otrDealerEN : articleCategories.otrDealerFR,
  order: '-sys.updatedAt',
  // limit: 5,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.hero,fields.subCategory'
});

/**
 * A query to get OTR Hub Article (ALD)
 * @author John Andaya
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryOTRHubArticle = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': 'ALD',
  order: '-sys.updatedAt',
  limit: 1,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.createdAt,fields.title,fields.summary,fields.article,fields.category,fields.subCategory,fields.brands,fields.regions,fields.classOfTrade,fields.needsAcknowledgment'
});

/**
 * A query to get OTR Hub Article for Winter Cargo Distribution (CWD)
 * @author Michael Lipski
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryOTRHubArticleCWD = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': 'Winter Cargo Distribution',
  order: '-sys.updatedAt',
  limit: 1,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.createdAt,fields.title,fields.summary,fields.article,fields.category,fields.subCategory,fields.brands,fields.regions,fields.classOfTrade,fields.needsAcknowledgment'
});

/**
 * A query to get Terms and Conditions Article
 * @author John Andaya
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.entryTitle,fields.article,fields.startDate,fields.endDate,fields.colour
*/
export const queryTermsAndConditionsArticle = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.category.sys.contentType.sys.id': stationPortalCategory,
  'fields.subCategory.sys.contentType.sys.id': stationPortalSubCategory,
  'fields.subCategory.fields.subCategory': articleCategories.termsOfUse,
  'fields.announcement': False,
  'fields.criticalUpdate': False,
  limit: 1,
  locale: getContentfulLocale(locale),
  select: 'sys.id,sys.createdAt,fields.title,fields.article,fields.category,fields.subCategory,fields.brands,fields.regions,fields.classOfTrade,fields.needsAcknowledgment'
});

/**
 * Gets a specific contentful tag
 * @author Kevin Parkinson
 * @param {string} selectedTag
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const queryTag = (selectedTag: string, locale: String): any => ({
  content_type: tag,
  'fields.tag': selectedTag,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.tag'
});

/**
 * A query to get all Articles in a summarised form from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @selects fields: sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2
*/
export const queryAllArticlesAsSummaries = (locale: String): any => ({
  content_type: stationPortalArticle,
  locale: getContentfulLocale(locale),
  limit: 1000,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu'
});

/**
 * A query to get all Articles favourited by a user from Contentful
 * @author Kevin Parkinson
 * @param {string[]} bookmarks
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryFavouritedArticles = (bookmarks: string[], locale: String): any => ({
  content_type: stationPortalArticle,
  'sys.id[in]': bookmarks.join(),
  locale: getContentfulLocale(locale)
});

/**
 * A query to get all acknowledgeable Articles from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryAcknowledgeableArticles = (locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.needsAcknowledgment': True,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get all acknowledgeable Articles from Contentful
 * @author Kevin Parkinson
 * @param {string} category -
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryAcknowledgeableArticlesInCategory = (category: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.needsAcknowledgment': True,
  'fields.category.sys.contentType.sys.id': stationPortalCategory,
  'fields.category.fields.category': category,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get all Planograms favourited by a user from Contentful
 * @author Kevin Parkinson
 * @param {string[]} bookmarks
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys, fields.title, fields.attachment, fields.planogramCategory
*/
export const queryFavouritedPlanograms = (bookmarks: string[], locale: String): any => ({
  content_type: planogram,
  'sys.id[in]': bookmarks.join(),
  locale: getContentfulLocale(locale),
  select: 'sys,fields.title,fields.attachment,fields.planogramCategory'
});

/**
 * A query to get all Planograms favourited by a user from Contentful
 * @author Kevin Parkinson
 * @param {string[]} bookmarks
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys, fields.title, fields.attachment, fields.planogramCategory
*/
export const queryFavouritedPlanogramsFull = (bookmarks: string[], locale: String): any => ({
  content_type: planogram,
  'sys.id[in]': bookmarks.join(),
  locale: getContentfulLocale(locale),
  select: 'sys,fields.title,fields.attachment,fields.tags,fields.planogramCategory,fields.shelfSize'
});

/**
 * A query to get all Frequently Asked Questions (FAQs) from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select fields.question, fields.answer, fields.faqCategory
*/
export const queryFAQs = (locale: String): any => ({
  content_type: faq,
  locale: getContentfulLocale(locale),
  select: 'fields.question,fields.answer,fields.faqCategory'
});

/**
 * A query to get all Article categories from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.category
*/
export const queryCategories = (locale: String): any => ({
  content_type: stationPortalCategory,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.category'
});

/**
 * A query to get all Planogram categories from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.planogramCategory
*/
export const queryPlanogramCategories = (locale: String): any => ({
  content_type: stationPortalPlanogramCategory,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.planogramCategory'
});

/**
 * A query to get all Article subcategories from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, fields.subCategory
*/
export const querySubCategories = (locale: String): any => ({
  content_type: stationPortalSubCategory,
  locale: getContentfulLocale(locale),
  select: 'sys.id,fields.subCategory'
});

/**
 * A query to get all Article subcategory 2s from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const querySubCategory2s = (locale: String): any => ({
  content_type: stationPortalSubCategory2,
  locale: getContentfulLocale(locale),
  select: 'fields.subCategory2'
});

/**
 * A query to get a Brand by name from Contentful
 * @author Kevin Parkinson
 * @param {brandCode} locale
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryBrandByName = (brandCode: string, locale: String): any => ({
  content_type: brand,
  'fields.mdmCode': brandCode,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get a list of all class of trades from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryClassOfTrades = (locale: String): any => ({
  content_type: classOfTrade,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get a list of all regions from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryRegions = (locale: String): any => ({
  content_type: region,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get a list of all divisions from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryDivisions = (locale: String): any => ({
  content_type: division,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get a list of all tags from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryTags = (locale: String): any => ({
  content_type: tag,
  locale: getContentfulLocale(locale)
});

/**
 * A query to get a list of all locales from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryBrands = (locale: String): any => ({
  content_type: brand,
  locale: getContentfulLocale(locale)
});

/**
 * A query to search articles in Contentful
 * @author Kevin Parkinson
 * @param {string} searchTerm
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2
*/
export const searchArticles = (searchTerm: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.title[match]': searchTerm,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu,fields.tags'
});

export const searchArticlesByTag = (searchTag: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.tags[in]': searchTag,
  query: searchTag,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.specificBu,fields.tags'
});

/**
 * A query to search articles in Contentful
 * @author Kevin Parkinson
 * @param {string} searchTerm
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const searchArticlesWithFilters = (searchTerm: string, locale: String): any => ({
  content_type: stationPortalArticle,
  'fields.title[match]': searchTerm,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys.id,sys.updatedAt,fields.title,fields.summary,fields.category,fields.subCategory,fields.subCategory2,fields.tags,fields.specificBu,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to search entries in Contentful
 * @author Kevin Parkinson
 * @param {string} searchTerm
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2
*/
export const searchPlanograms = (searchTerm: string, locale: String): any => ({
  content_type: planogram,
  'fields.title[match]': searchTerm,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys,fields.title,fields.attachment,fields.planogramCategory,fields.tags'
});

/**
 * A query to search entries in Contentful
 * @author Kevin Parkinson
 * @param {string} searchTerm
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
 * @select sys.id, sys.updatedAt, fields.title, fields.summary, fields.category, fields.subCategory, fields.subCategory2, fields.brands, fields.regions, fields.classOfTrade
*/
export const searchPlanogramsWithFilters = (searchTerm: string, locale: String): any => ({
  content_type: planogram,
  'fields.title[match]': searchTerm,
  locale: getContentfulLocale(locale),
  limit: 300,
  select: 'sys,fields.title,fields.attachment,fields.planogramCategory,fields.tags,fields.brands,fields.regions,fields.classOfTrade'
});

/**
 * A query to get a list of all entries from Contentful
 * @author Kevin Parkinson
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryEntries = (locale: String): any => ({ locale: getContentfulLocale(locale) });

// Generic
/**
 * A query to get a list of all entries by specific content type from Contentful
 * @author Kevin Parkinson
 * @param {string} contentType
 * @param {string} locale - the Contentful locale to get content in: en-US | fr-CA
*/
export const queryByContentType = (contentType: string, locale: String): any => ({
  content_type: contentType,
  locale: getContentfulLocale(locale)
});
