import provinces from 'provinces-ca';
import { appConfig } from 'src/config/config';

const {
  rdoc: opModelRDOC
} = appConfig.gatewayAPI.operatingModels;
const {
  coop,
  coro,
  cosa,
  dodo,
  franchise,
  na,
  rdoc
} = appConfig.gatewayAPI.operatingModels.codes;
const {
  coro: contentfulCoro,
  cosa: contentfulCOSA,
  dodo: contentfulDODO,
  rdoc: contentfulRDOC,
  otrFranchise: contentfulFranchise
} = appConfig.contentful.classOfTrades;

interface GatewayStation{
  PK: string;
  SK: string;
  site_id: string;
  address: string;
  brand: string;
  city: string;
  email: string;
  fax: string;
  gps_lat: string;
  gps_lon: string;
  is_otr_dealer: boolean;
  car_wash_flag: boolean;
  site_description: string;
  operating_model: string;
  phone: string;
  postal: string;
  province: string;
  rom: string;
  rom_email: string;
  tm_email: string;
}

export const getEmptyStation = (): GatewayStation => ({
  PK: '',
  SK: '',
  site_id: '',
  address: '',
  brand: '',
  city: '',
  email: '',
  fax: '',
  gps_lat: '',
  gps_lon: '',
  is_otr_dealer: false,
  car_wash_flag: false,
  site_description: '',
  operating_model: '',
  phone: '',
  postal: '',
  province: '',
  rom: '',
  rom_email: '',
  tm_email: ''
});

/**
 * Takes an MDM/Gateway station and maps the operating model to a string representing a class of trade in Contentful
 * @author Kevin Parkinson
 * @param station (gatewayStation) the station from MDM
*/
export const classOfTradeCode = (station: GatewayStation) => {
  if (!station || !station.operating_model) return '';
  const { operating_model: op } = station;
  let contentfulClassOfTrade;
  if (op.startsWith(coop) || op.startsWith(coro)) contentfulClassOfTrade = contentfulCoro;
  if (op.startsWith(cosa)) contentfulClassOfTrade = contentfulCOSA;
  if (op.startsWith(dodo)) contentfulClassOfTrade = contentfulDODO;
  if (op.startsWith(rdoc)) contentfulClassOfTrade = contentfulRDOC;
  if (op.startsWith(franchise)) contentfulClassOfTrade = contentfulFranchise;
  if (op.startsWith(na) || !op) contentfulClassOfTrade = '';
  return contentfulClassOfTrade;
};

/**
 * Takes an MDM/Gateway station and maps the operating model to a string representing a class of trade in Contentful
 * @author Kevin Parkinson
 * @param stationOpertingModel (gatewayStation) the station's operating model from MDM
*/
export const classOfTradeDescription = (stationOpertingModel: string) => {
  if (!stationOpertingModel) return '';
  const cotDescription = stationOpertingModel.indexOf('-') > -1 ? stationOpertingModel.substring(stationOpertingModel.indexOf('-') + 1) : stationOpertingModel;
  return cotDescription;
};

/**
 * Takes the mdm brand name in format [abbr]-[brandname] and returns just the brandname
 * @author Kevin Parkinson
 * @param {string} mdmBrand the station's mdm brand
*/
export const getBrandName = (mdmBrand: string) => {
  if (!mdmBrand) return '';
  const brandName = mdmBrand.substring(mdmBrand.indexOf('-') + 1, mdmBrand.length);
  return brandName;
};
/**
 * Takes an MDM/Gateway station and converts the province abbreviation to the province full name
 * @author Kevin Parkinson
 * @param station (gatewayStation) the station from MDM
*/
export const provinceName = (station: GatewayStation) => {
  if (!station || !station.province) return '';
  const foundProvince = provinces.find((p) => p.abbreviation === station.province.trim());
  return foundProvince.name;
};

/**
 * Takes an MDM/Gateway station and converts the city to a nice looking city name
 * @author Kevin Parkinson
 * @param station (gatewayStation) the station from MDM
*/
export const friendlyCityName = (station: GatewayStation) => {
  if (!station || !station.province || !station.city) return '';
  const splitCity = station.city.split('-');
  let friendlyCity = '';
  for (let i = 0; i < splitCity.length; i++) {
    if (i !== 0) friendlyCity += '-';
    friendlyCity += splitCity[i].charAt(0).toUpperCase() + splitCity[i].substr(1).toLowerCase();
  }
  return friendlyCity;
};

export const isCommissionDealer = (station: GatewayStation) => station.operating_model === opModelRDOC;

export const stationHasGPSCoords = (station: GatewayStation) => station && station.gps_lat && station.gps_lon;

export default GatewayStation;
