// React
import {
  useState,
  useEffect,
  useCallback
} from 'react';
import type { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { isUndefined } from 'lodash';

// Material UI
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Store
import type { RootState } from 'src/store';
import { useSelector, useDispatch } from 'src/store';
import {
  setUser,
  setApplyFilters,
  setShowContent,
  setUninitialisedUserType,
  setSelectedStation,
  setSelectedBrand,
  setUpdatedDate
} from 'src/slices/userDetails';
import {
  saveBrands,
  saveClassOfTrades,
  saveRegions
} from 'src/slices/contentfulLookup';

// Styles
import {
  DashboardLayoutRoot,
  DashboardLayoutWrapper,
  DashboardLayoutContainer,
  DashboardLayoutContent
} from 'src/style/dashboardLayoutStyles';

// Services
import { gatewayService } from 'src/services/gatewayService';
import { contentfulService } from 'src/services/contentfulService';

// Custom components
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardLayoutProps from 'src/types/props/dashboardLayoutProps';
import { User } from 'src/types/gateway';
import UserRegistration from 'src/pages/dashboard/UserRegistration';

// Hooks and utils
import { appConfig } from 'src/config/config';
import store from 'src/store/localStore';
import logger from 'src/logging/logger';
import { getLogMsg, getVerboseLogMsg } from 'src/utils/loggingUtils';
import useMounted from 'src/hooks/useMounted';
import useAuth from 'src/hooks/useAuth';
import {
  unconditionallySetUserActivityDateTime,
  getUserActivityDateTime,
  isUserActivityExpired
} from 'src/utils/cookieUtils';
import { getSessionEmail } from 'src/utils/authUtils';
import { geti18nLocale } from 'src/utils/localeHelper';
import { useTranslation } from 'react-i18next';
import { isRegularUser, isAdminOrTMUser, allAssignedStations, isEmptyUser } from 'src/types/gateway/gatewayUser';
import { shouldIApplyFilters } from 'src/utils/contentFilterUtils';
import { getEmptyBrand } from 'src/types/brand';
import { getEmptyStation } from 'src/types/gateway/gatewayStation';
import AuthorizationRequired from 'src/pages/AuthorizationRequired';

const {
  userLatitude,
  userLongitude,
  defaultLatitude,
  defaultLatitudeValue,
  defaultLongitude,
  defaultLongitudeValue
} = appConfig.localStorage;
const { contentfulEnglish } = appConfig.i18n.languages;
const { sessionHasExpired } = appConfig.i18n.messages;
const { parklandEmailDomain } = appConfig.appSettings;
const componentName = 'DashboardLayout';

if (navigator.geolocation) {
  navigator.geolocation.getCurrentPosition(
    (position: any) => {
      store.set(userLatitude, position.coords.latitude);
      store.set(userLongitude, position.coords.longitude);
    },
    () => {
      store.set(defaultLatitude, defaultLatitudeValue);
      store.set(defaultLongitude, defaultLongitudeValue);
    }, { timeout: 10000 }
  );
}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  // State variables
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);

  // Hooks and Utils
  const mounted = useMounted();
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();
  const location = useLocation();
  const auth = useAuth();
  logger.verbose(getVerboseLogMsg(componentName, 'free form in component', 'location'), location);

  // Selectors
  const gatewayUser = useSelector((state: RootState): User | null => state.userDetails.gatewayUserDetails);

  // Functions
  const applyUserLanguage = (userLanguage: string): void => {
    if (!userLanguage) return;
    const userSelected18nLanguage = geti18nLocale(userLanguage);
    if (userSelected18nLanguage !== i18n.language) i18n.changeLanguage(userSelected18nLanguage);
  };

  const loadData = async () => {
    logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'setIsLoading'));
    setIsLoading(true);
    unconditionallySetUserActivityDateTime();
    // init contentful lookups
    const brandsList = await contentfulService.getBrands(contentfulEnglish); // only user English to validate brands
    dispatch(saveBrands(brandsList));
    const classOfTradeList = await contentfulService.getClassOfTrades(contentfulEnglish); // only user English to validate class of trades/operating models
    dispatch(saveClassOfTrades(classOfTradeList));
    const regionsList = await contentfulService.getRegions(contentfulEnglish); // only user English to validate regions
    dispatch(saveRegions(regionsList));
    logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'got brands, regions, class of trades'));
    // END init contentful lookups

    // Get user
    const email = await getSessionEmail();
    const user = await gatewayService.getUser(email);
    dispatch(setUser(user));
    logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'got user:'), user);
    // END Get user

    // Set uninitialised user type
    if (!user) {
      if (email.includes(parklandEmailDomain)) {
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'Uninitialised Corporate user detected'));
        dispatch(setUninitialisedUserType('Corporate'));
      } else {
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'Uninitialised Station user detected'));
        dispatch(setUninitialisedUserType('Station'));
      }
    }
    if (user && !user.first_name) {
      logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'Uninitialised Station user detected'));
      dispatch(setUninitialisedUserType('Station'));
    }
    if (user && user.first_name) {
      logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'Initialised user detected'));
      dispatch(setUninitialisedUserType('Initialised'));

      // Set show content for user
      if (isAdminOrTMUser(user)) {
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'admin user detected. Show content and do not apply filters.'));
        dispatch(setApplyFilters(false));
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'do not apply filters.'));
        dispatch(setShowContent(true));
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'show content.'));
        dispatch(setSelectedStation(getEmptyStation()));
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'set empty selected station.'));
        dispatch(setSelectedBrand(getEmptyBrand()));
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'set empty selected brand.'));
        dispatch(setUpdatedDate());
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'set updated date.'));
      } else if (isRegularUser(user)) {
        logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'regular user detected.'));
        // Set selected station and brand
        let station;
        const allStations = allAssignedStations(user);
        let stationID = '';
        let brand;
        // User user's default station if they have one
        if (user.default_station) stationID = user.default_station;
        // if the user has no default station set but they have assigned stations, pick the first one
        if (!stationID && allStations && allStations.length > 0) {
          const [first] = allStations;
          stationID = first.site_id;
        }
        if (stationID) {
          station = await gatewayService.getStation(stationID);
          dispatch(setSelectedStation(station));
          logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'setting selected station:'), station);
          // the station.brand is the MDMCode
          if (station && station.brand) {
            brand = await contentfulService.getBrand(station.brand, i18n.language);
            if (brand) {
              dispatch(setSelectedBrand(brand));
              logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'setting selected brand:'), brand);
              const applyFilters = shouldIApplyFilters(station, brandsList, classOfTradeList, regionsList);
              setShowContent(applyFilters);
              dispatch(setApplyFilters(applyFilters));
              dispatch(setShowContent(applyFilters));
              dispatch(setUpdatedDate());
            }
          }
        } else {
          logger.verbose(getVerboseLogMsg(componentName, 'loadData', 'could not determine stations; showing no content.'));
          setShowContent(false);
          dispatch(setApplyFilters(false));
          dispatch(setShowContent(false));
        }
        // END Set selected station and brand
      }
      // END Set show content for user
    }
    setIsLoading(false);
  };

  const init = useCallback(async () => {
    try {
      if (window.location.pathname !== '/') gatewayService.logPageView();
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'CALLED'));
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'auth.isAuthenticated'), auth.isAuthenticated);
      const userActivityDateTime = getUserActivityDateTime();
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'userActivityDateTime: '), userActivityDateTime);
      const activityExpired = isUserActivityExpired();
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'activityExpired: '), activityExpired);
      const emptyUser = isEmptyUser(gatewayUser);
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'emptyUser: '), emptyUser);
      if (auth.isAuthenticated) {
        logger.verbose(getVerboseLogMsg(componentName, 'init', 'user is authenticated'));
        if (!userActivityDateTime) {
          logger.verbose(getVerboseLogMsg(componentName, 'init', 'no userActivityDateTime cookie found. loading data ...'));
          loadData();
        } else if (emptyUser) {
          logger.verbose(getVerboseLogMsg(componentName, 'init', 'no user found. loading data ...'));
          loadData();
        } else if (activityExpired) {
          logger.verbose(getVerboseLogMsg(componentName, 'init', `user activity expired?: ${activityExpired}, is empty user?: ${emptyUser}. loading data ...`));
          toast(translate(sessionHasExpired).toString());
          loadData();
        } else if (!activityExpired) {
          logger.verbose(getVerboseLogMsg(componentName, 'init', 'updating user activity cookie'));
          unconditionallySetUserActivityDateTime();
        }
      } else {
        logger.verbose(getVerboseLogMsg(componentName, 'init', 'user is NOT authenticated'));
      }
    } catch (err) {
      logger.error(getLogMsg('init', err));
    }
    logger.verbose(getVerboseLogMsg(componentName, 'init', 'gatewayUser '), gatewayUser);
    logger.verbose(getVerboseLogMsg(componentName, 'init', 'END'));
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init, location]);

  useEffect(() => {
    // Set user language
    if (gatewayUser) {
      logger.info(getLogMsg('Preferred Language', `setting site language to user specified language: ${gatewayUser.language}`));
      applyUserLanguage(gatewayUser.language);
    }
    // END Set user language
  });

  return (
    <DashboardLayoutRoot>
      {loading
      && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {!loading && isUndefined(gatewayUser)
        && (
          <UserRegistration />
        )}
      {!loading && gatewayUser && gatewayUser.is_deactivated
        && (
          <AuthorizationRequired />
        )}
      {!loading && gatewayUser && !gatewayUser.is_deactivated
      && (
        <>
          <DashboardNavbar
            onSidebarOpen={(): void => setIsSidebarOpen(true)}
          />
          <DashboardSidebar
            onSidebarClose={(): void => setIsSidebarOpen(false)}
            openSidebar={isSidebarOpen}
          />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <Outlet />
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </>
      )}
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
