import { EntryCollection, Entry } from 'contentful';

// Custom types
import { ContentfulPlanogramWithFilters } from 'src/types/contentful/stationPortalPlanogramCollection';
import ContentfulPlanogramSummary from 'src/types/contentful/contentfulPlanogramSummary';
import ContentfulPlanogramSummaryWithFilters from 'src/types/contentful/contentfulPlanogramSummaryWithFilters';
import Planogram from 'src/types/planogram';
import FavouritedPlanogram from 'src/types/favouritedPlanogram';
import PlanogramSummary from 'src/types/planogramSummary';

// Data converters
import {
  simplifyContentfulBrandsAny,
  simplifyContentfulRegionsAny,
  simplifyContentfulClassOfTrades
} from 'src/converters/contentful';

/**
 * Converts a list of planograms in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {ContentfulPlanogramWithFilters[]} planograms - the Contentful planograms to convert
 * @return {Planogram[]}
*/
export const toPlanogramsWithFilters = (planograms: ContentfulPlanogramWithFilters[]): Planogram[] => planograms.map((p) => ({
  id: p.sys ? p.sys.id : '',
  title: p.title ?? '',
  updatedDate: p.sys ? p.sys.publishedAt : '',
  summary: p.summary ?? '',
  category: p.planogramCategory ? p.planogramCategory.planogramCategory : '',
  shelfSize: p.shelfSize ? p.shelfSize.shelfSize : '',
  url: p.attachment && p.attachment.url ? p.attachment.url : '',
  tags: p.tags && p.tags.items ? p.tags.items.map((t) => (t.tag)) : [],
  classOfTrade: p.classOfTradeCollection && p.classOfTradeCollection.items ? p.classOfTradeCollection.items.map((cot) => ((cot && cot.classOfTrade) ?? '')) : [],
  regions: p.regionsCollection && p.regionsCollection.items ? p.regionsCollection.items.map((r) => ((r && r.region) ?? '')) : [],
  brands: p.brandsCollection && p.brandsCollection.items ? p.brandsCollection.items.map((b) => ((b && b.brand) ?? '')) : []
}));

/**
 * Converts a list of planograms in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {ContentfulPlanogram[]} planograms - the Contentful planograms to convert
 * @return {Planogram[]}
*/
export const toFavouritedPlanograms = (planograms: EntryCollection<any>): FavouritedPlanogram[] => planograms.items.map((p) => ({
  id: p.sys ? p.sys.id : '',
  title: p && p.fields && p.fields.title ? p.fields.title : '',
  updatedDate: p.sys ? p.sys.updatedAt : '',
  summary: p && p.fields && p.fields.summary ? p.fields.summary : '',
  category: p && p.fields && p.fields.planogramCategory && p.fields.planogramCategory.fields.planogramCategory ? p.fields.planogramCategory.fields.planogramCategory : '',
  shelfSize: p && p.fields && p.fields.shelfSize && p.fields.shelfSize.fields && p.fields.shelfSize.fields.shelfSize ? p.fields.shelfSize.fields.shelfSize : '',
  url: p && p.fields && p.fields.attachment && p.fields.attachment.fields && p.fields.attachment.fields.file && p.fields.attachment.fields.file.url ? p.fields.attachment.fields.file.url : '',
  tags: p && p.fields.tags && p.fields.tags ? p.fields.tags.map((t) => (t.fields.tag)) : []
}));

/**
 * Converts a list of planograms in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulPlanogramSummary>} planograms - the Contentful planograms to convert
 * @return {PlanogramSummary[]}
*/
export const toPlanogramSummaries = (planograms: EntryCollection<ContentfulPlanogramSummary>): PlanogramSummary[] => planograms.items.map((p) => ({
  id: p.sys ? p.sys.id : '',
  title: p.fields.title ?? '',
  updatedDate: p.sys && p.sys.createdAt ? p.sys.createdAt : '',
  category: p.fields.planogramCategory && p.fields.planogramCategory.fields && p.fields.planogramCategory.fields.planogramCategory ? p.fields.planogramCategory.fields.planogramCategory : '',
  url: p.fields.attachment && p.fields.attachment.fields && p.fields.attachment.fields.file && p.fields.attachment.fields.file.url ? p.fields.attachment.fields.file.url : '',
  tags: p && p.fields.tags && p.fields.tags ? p.fields.tags.map((t) => (t.fields.tag)) : []
}));

/**
 * Converts a list of planograms in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulPlanogramSummary>} planograms - the Contentful planograms to convert
 * @return {PlanogramSummary[]}
*/
export const toPlanogramSummariesFiltered = (planograms: Entry<ContentfulPlanogramSummaryWithFilters>[]): PlanogramSummary[] => planograms.map((p) => ({
  id: p.sys ? p.sys.id : '',
  title: p.fields.title ?? '',
  updatedDate: p.sys && p.sys.createdAt ? p.sys.createdAt : '',
  category: p.fields.planogramCategory && p.fields.planogramCategory.fields && p.fields.planogramCategory.fields.planogramCategory ? p.fields.planogramCategory.fields.planogramCategory : '',
  url: p.fields.attachment && p.fields.attachment.fields && p.fields.attachment.fields.file && p.fields.attachment.fields.file.url ? p.fields.attachment.fields.file.url : '',
  brands: p.fields.brands ? simplifyContentfulBrandsAny(p.fields.brands) : [],
  regions: p.fields.regions ? simplifyContentfulRegionsAny(p.fields.regions) : [],
  classOfTrade: p.fields.classOfTrade ? simplifyContentfulClassOfTrades(p.fields.classOfTrade) : [],
  tags: p && p.fields.tags && p.fields.tags ? p.fields.tags.map((t) => (t.fields.tag)) : []
}));
