// React
import type { FC } from 'react';
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';

// Amplify
import { Auth } from 'aws-amplify';

// Material UI
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

// Services
import { contentfulService } from 'src/services/contentfulService';

// Data
// import timezones, { Timezone } from 'timezones.json';
import { timezonesEN, timezonesFR } from 'src/data/timezones';

// Custom types
import LanguageOption from 'src/types/languageOption';
import UserSettingsFormProps from 'src/types/props/userSettingsFormProps';
import GatewayUser, { allAssignedStations } from 'src/types/gateway/gatewayUser';
import Timezone from 'src/types/timezone';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Hooks and utils
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/config';
import useMounted from 'src/hooks/useMounted';
import detectBrowserLanguage from 'detect-browser-language';
import logger from 'src/logging/logger';
import { getLogMsg, getVerboseLogMsg } from 'src/utils/loggingUtils';

const UserSettingsForm: FC<UserSettingsFormProps> = ({ isInitialSave, onSubmitChanges }: UserSettingsFormProps) => {
  // State variables
  const [gatewayUser, setSetGatewayUser] = useState<GatewayUser>();
  const [languages, setLanguages] = useState<LanguageOption[]>();
  const [timezones, setTimezones] = useState<Timezone[]>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<string>('');
  const [selectedPreferredStation, setSelectedPreferredStation] = useState<string>('');
  const componentName = 'UserSettingsForm';

  // Hooks and utils
  const { t: translate, i18n } = useTranslation();
  const mounted = useMounted();

  // Constants
  const { formFields } = appConfig.i18n;
  const { saveChanges } = appConfig.i18n.actions;
  const { contentfulEnglish, contentfulFrench, i18nEnglish, i18nFrench } = appConfig.i18n.languages;

  // Data
  const init = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      const { email } = session.getIdToken().payload;
      const locales = await contentfulService.getLocales();
      setLanguages(locales);
      if (isInitialSave) {
        gatewayService.getUser(email)
          .then(async (user) => {
            if (user) {
              setSetGatewayUser(user);
              const assignedStations = allAssignedStations(user);
              setSelectedPreferredStation(assignedStations.length > 0 ? assignedStations[0].site_id : '');
            }
          })
          .catch((err) => {
            logger.error(getLogMsg('init', err));
          });
        const browserLanguage = detectBrowserLanguage();
        // try to determine the user's language based on their browser settings
        if (browserLanguage.indexOf('fr') > -1) {
          i18n.changeLanguage(i18nFrench);
          setSelectedLanguage(contentfulFrench);
          setTimezones(timezonesFR);
        } else {
          i18n.changeLanguage(i18nEnglish);
          setSelectedLanguage(contentfulEnglish);
          setTimezones(timezonesEN);
        }
        // set timezone to central north amercia by default
        setSelectedTimezone('Pacific Daylight Time');
      } else {
        gatewayService.getUser(email)
          .then(async (user) => {
            setSetGatewayUser(user);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setCity(user.city);
            if (user.language === contentfulEnglish) {
              setTimezones(timezonesEN);
            } else {
              setTimezones(timezonesFR);
            }
            setSelectedLanguage(user.language);
            setSelectedTimezone(user.timezone);
            setSelectedPreferredStation(user.default_station);
          })
          .catch((err) => {
            logger.error(getLogMsg('init', err));
          });
      }
    } catch (err) {
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg('User Settings - init', err));
    }
  }, [mounted]);

  const siteLanguageChange = useCallback(async () => {
    try {
      if (i18n.language === contentfulEnglish) {
        setTimezones(timezonesEN);
      } else {
        setTimezones(timezonesFR);
      }
    } catch (err) {
      logger.error(getLogMsg('User Settings - siteLanguageChange', err));
    }
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    siteLanguageChange();
  }, [i18n.language]);

  // Event Handlers
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handlePrefferredStationChange = (event) => {
    setSelectedPreferredStation(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleSubmit = (event) => {
    logger.verbose(getVerboseLogMsg(componentName, 'handleSubmit', 'onSubmitChanges'), event);
    try {
      if (firstName.length === 0 || city.length === 0 || lastName.length === 0) {
        toast.error('Please fill in required field(s)(*)', { duration: 6000 });
      } else {
        logger.verbose(getVerboseLogMsg(componentName, 'handleSubmit', 'onSubmitChanges'), 'executing onSubmitChanges');
        onSubmitChanges(firstName,
          lastName,
          city,
          selectedLanguage,
          selectedTimezone,
          selectedPreferredStation,
          event);
      }
    } catch (err) {
      toast.error(err);
      logger.error(getLogMsg('handleSubmit', err));
    }
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container>
        {isInitialSave
        && (
          <Grid
            item
            xs={12}
            sx={{
              pb: 2
            }}
          >
            <Typography variant="h5">
              {translate(formFields.tellUsMore)}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <TextField
            fullWidth
            value={firstName}
            label={translate(formFields.firstName)}
            onChange={handleFirstNameChange}
            size="small"
            required
          />
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <TextField
            fullWidth
            value={lastName}
            label={translate(formFields.lastName)}
            onChange={handleLastNameChange}
            size="small"
            required
          />
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <TextField
            fullWidth
            value={city}
            label={translate(formFields.city)}
            onChange={handleCityChange}
            size="small"
            required
          />
        </Grid>
        {gatewayUser
        && !gatewayUser.is_admin
        && !gatewayUser.is_super
        && !gatewayUser.is_tm
        && (gatewayUser.mdm_stations || gatewayUser.stations)
        && (gatewayUser.mdm_stations.length > 0 || gatewayUser.stations.length > 0)
        && (
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            sx={{
              p: 1
            }}
          >
            <Box>
              <FormControl fullWidth>
                <InputLabel id="select-language-label">{translate(formFields.preferredStation)}</InputLabel>
                <Select
                  multiple={false}
                  value={selectedPreferredStation}
                  label={translate(formFields.preferredStation)}
                  fullWidth
                  onChange={handlePrefferredStationChange}
                  size="small"
                >
                  {allAssignedStations(gatewayUser).map((station) => (
                    <MenuItem
                      key={station.site_id}
                      value={station.site_id}
                    >
                      <ListItemText primary={station.site_description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-language-label">{translate(formFields.language)}</InputLabel>
              <Select
                multiple={false}
                value={selectedLanguage}
                label={translate(formFields.language)}
                fullWidth
                onChange={handleLanguageChange}
                size="small"
              >
                {languages && languages.map((language: LanguageOption) => (
                  <MenuItem
                    key={language.code}
                    value={language.code}
                  >
                    <ListItemText primary={language.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <Box>
            <FormControl fullWidth>
              <InputLabel id="select-timezone-label">{translate(formFields.timezone)}</InputLabel>
              <Select
                multiple={false}
                value={selectedTimezone}
                label={translate(formFields.timezone)}
                fullWidth
                onChange={handleTimezoneChange}
                size="small"
              >
                {timezones && timezones.map((timezone: Timezone) => (
                  <MenuItem
                    key={timezone.text}
                    value={timezone.value}
                  >
                    <ListItemText primary={timezone.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          textAlign="right"
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            p: 1
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="outlined"
            color="primary"
          >
            {translate(saveChanges)}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSettingsForm;
