// Amplify
import { API, Auth } from 'aws-amplify';

// Custom types
import {
  Article,
  User,
  Station,
  StationSummary,
  StationEngagement,
  TeamUser
} from 'src/types/gateway';
import UserActivityType from 'src/types/gateway/userActivityType';
import UserActivityReportItem from 'src/types/gateway/userActivityReportItem';
import TeamManagedReportItem from 'src/types/gateway/teamManagedReportItem';
import CommissionReportResult from 'src/types/commissionReportResult';
import SiteConfigurationResult from 'src/types/siteConfigurationResult';
import MysteryShopperReportItem from 'src/types/mysteryShopperReportItem';
import AgeRestrictedShopReportItem from 'src/types/ageRestrictedShopReportItem';
import EmailAttachment from 'src/types/emailAttachment';
import StationOTRSelection from 'src/types/gateway/stationOTRSelection';
import FuelPrice from 'src/types/fuelPrice';
import OTRHubReportResult from 'src/types/oTRHubReportResult';
import CloudSearchResults from 'src/types/cloudSearchResults';
import ProductOut from 'src/types/productOut';
import Comment from 'src/types/comment';

// utils and Config
import { appConfig } from 'src/config/config';
import {
  standardGatewayRejectedPromise,
  getAPIPath,
  gatewayGetRequestWithAuth as getRequestWithAuth,
  gatewayUpdateRequestWithAuth as updateRequestWithAuth,
  gatewayGetRequestWithAuthAndParams,
  logHarmlessErrorAsinfo
} from 'src/services/resources/requestResponse';
import { getSessionEmail } from 'src/utils/authUtils';
import { store } from 'src/store/index';
import {
  standardURLFormatDate,
  toShortDate,
  getDateInStandardYMDFormat,
  getDateInStandard12HourAMPMFormat
} from 'src/utils/dateUtils';
import subYears from 'date-fns/subYears';
import { toMysteryShopReportItems, ageRestrictedToMysteryShopReportItems } from 'src/converters/mysteryShopConverter';
import { toCloudSearchResults } from 'src/converters/contentful/searchConverter';
import logger from 'src/logging/logger';
import _, { cloneDeep } from 'lodash';
import { filterSearchResult } from 'src/services/resources/gatewayFilters';

// Constants
const { apiName } = appConfig.gatewayAPI;
const { dealer, user, station, article, report, mail, search } = appConfig.gatewayAPI.paths;
const serviceName = 'Gateway Service';

const getJWTAuthToken = async (): Promise<string> => new Promise((resolve, reject) => {
  Auth.currentSession()
    .then((session) => {
      const jwtToken = session.getIdToken().getJwtToken();
      resolve(jwtToken);
    })
    .catch((error) => standardGatewayRejectedPromise(reject, error));
});

class GatewayService {
  // USER
  /**
   * Gets a user from the Gateway
   * @author Kevin Parkinson
   * @param {string} email - The email of the user you wish to fetch
   * @return {Promise<User[]]>}
  */
  async getUser(email: string): Promise<User> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(user.base, [email.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data.Items[0]))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Toggles whether someone is an admin or not
   * @author Kevin Parkinson
   * @param {string} email - The email of the user you wish to fetch
   * @return {Promise<string>}
  */
  async toggleAdmin(email: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.admin, email.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Toggles whether someone is a TM or not
   * @author Kevin Parkinson
   * @param {string} email - The email of the user you wish to fetch
   * @return {Promise<string>}
  */
  async toggleTM(email: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.tm, email.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Toggles whether someone is a super admin or not
   * @author Kevin Parkinson
   * @param {string} email - The email of the user you wish to fetch
   * @return {Promise<string>}
  */
  async toggleSuper(email: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.super, email.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Saves/initializes user details for a Gateway user
   * @author Kevin Parkinson
   * @param {string} oktaID - The OKTA ID of the user you are updating
   * @param {string} firstName - self-explanatory
   * @param {string} lastName - self-explanatory
   * @param {string} email - self-explanatory
   * @param {string} city - self-explanatory
   * @param {string} language - self-explanatory
   * @param {string} timezone - self-explanatory
   * @param {string} defaultStation - the site_id of the station the user has selected to be their default
   * @return {Promise<User>}
  */
  async saveUserSettings(oktaID: string, firstName: string, lastName: string, email: string, city: string, language: string, timezone: string, defaultStation: string): Promise<User> {
    const jwtToken = await getJWTAuthToken();
    const postBody = {
      okta_id: oktaID,
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      city,
      language,
      timezone,
      defaultStation
    };
    const postData = updateRequestWithAuth(postBody, jwtToken);
    return new Promise((resolve, reject) => {
      API.post(apiName, getAPIPath(user.base), postData)
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Updates user settings for the given user
   * @author Kevin Parkinson
   * @param {string} firstName - self-explanatory
   * @param {string} lastName - self-explanatory
   * @param {string} email - self-explanatory
   * @param {string} city - self-explanatory
   * @param {string} language - self-explanatory
   * @param {string} timezone - self-explanatory
   * @param {string} defaultStation - the site_id of the station the user has selected to be their default
   * @return {Promise<User>}
  */
  async updateUser(firstName: string, lastName: string, email: string, city: string, language: string, timezone: string, defaultStation: string): Promise<User> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [email]), updateRequestWithAuth({ first_name: firstName, last_name: lastName, email: email.toLowerCase(), city, language, timezone, default_station: defaultStation }, jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Updates user settings for the given user
   * @author Kevin Parkinson
   * @param {string} email - self-explanatory
   * @param {string} updateLanguage - self-explanatory
   * @return {Promise<User>}
  */
  async updateUserLanguage(email: string, updateLanguage: string): Promise<User> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.language, email.toLowerCase()]), updateRequestWithAuth({ language: updateLanguage }, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of all of the users assigned to the given station
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @return {Promise<User>}
  */
  async getAllUsers(): Promise<User[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(user.base), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data.Items))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // Teams
  /**
   * Adds a user to the given site
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @param {string} userEmail - self-explanatory
   * @param {string} firstName - self-explanatory
   * @param {string} lastName - self-explanatory
   * @param {string} phoneNumber - self-explanatory
   * @return {Promise<string>}
  */
  async addUserToTeam(stationID: string, userEmail: string, firstName: string, lastName: string, phoneNumber: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    const postData = {
      site_id: stationID,
      email: userEmail,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber
    };
    return new Promise((resolve, reject) => {
      API.post(apiName, getAPIPath(user.base, [user.team]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Updates a user in a given site
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @param {string} userEmail - self-explanatory
   * @param {string} firstName - self-explanatory
   * @param {string} lastName - self-explanatory
   * @return {Promise<string>}
  */
  async updateUserInTeam(stationID: string, userEmail: string, firstName: string, lastName: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    const postData = {
      email: userEmail,
      first_name: firstName,
      last_name: lastName,
      timezone: '',
      city: '',
      site_id: stationID
    };
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.team, stationID]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Deletes a user from a given site
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @param {string} userEmail - self-explanatory
   * @return {Promise<string>}
  */
  async deleteUserFromTeam(stationID: string, userEmail: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const requestData = {
        email: userEmail,
        site_id: stationID
      };
      API.del(apiName, getAPIPath(user.base, [user.team]), updateRequestWithAuth(requestData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Deletes a user from a given site
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @param {string} userEmail - self-explanatory
   * @return {Promise<string>}
  */
  async disableUserInTeam(stationID: string, userEmail: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const requestData = {
        email: userEmail,
        activate: false,
        site_id: stationID
      };
      API.patch(apiName, getAPIPath(user.base, [user.team, user.status]), updateRequestWithAuth(requestData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Deletes a user from a given site
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @param {string} userEmail - self-explanatory
   * @return {Promise<string>}
  */
  async enableUserInTeam(stationID: string, userEmail: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const requestData = {
        email: userEmail,
        activate: true,
        site_id: stationID
      };
      API.patch(apiName, getAPIPath(user.base, [user.team, user.status]), updateRequestWithAuth(requestData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of all of the users assigned to the given station
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @return {Promise<TeamUser>}
  */
  async getAllUsersInTeam(stationID: string): Promise<TeamUser[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(user.base, [user.team, stationID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of all of the users assigned to the given station
   * @author Kevin Parkinson
   * @param {string} stationID - the station's site id
   * @return {Promise<User>}
  */
  async getAllUsersInStation(stationID: string): Promise<User[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(user.base, [stationID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations  who read a given article
   * @author Kevin Parkinson
   * @param {string} articleID - the article id
   * @return {Promise<StationEngagement>}
  */
  async getStationsWhoReadArticle(articleID: string): Promise<StationEngagement[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(report.base, [report.history, articleID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of articles read by a user in a station
   * @author Kevin Parkinson
   * @param {string} email
   * @param {string} siteID
   * @return {Promise<StationEngagement>}
  */
  async getArticlesReadByUserInStation(email: string, siteID: string): Promise<string[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(report.base, [report.getArticlesRead, email, siteID]), getRequestWithAuth(jwtToken))
        .then((response) => {
          const articleIds = response.results.map((a) => a.article_id);
          resolve(articleIds);
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations  who acknowledged a given article
   * @author Kevin Parkinson
   * @param {string} articleID - the article id
   * @return {Promise<StationEngagement>}
  */
  async getStationsWhoAcknowledgedArticle(articleID: string): Promise<StationEngagement[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(report.base, [report.acknowledge, articleID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations who acknowledged a given article
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {string} siteID - the currently selected site ID
   * @return {Promise<StationEngagement>}
  */
  async getArticlesAcknowledgedByUserAndSite(email: string, siteID?: string): Promise<string[]> {
    const jwtToken = await getJWTAuthToken();
    const postData = { email, site_id: siteID };
    return new Promise((resolve, reject) => {
      API.post(apiName, getAPIPath(report.base, [report.useracknowledgements]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response.data.map((d) => d.article)))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Assigns a list of station to the given user
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {string} stationID  - a list of station site ids
   * @return {Promise<string>}
  */
  async setStationsForUser(email: string, stations: StationSummary[]): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.put(apiName, getAPIPath(user.base, [user.stations, email.toLowerCase()]), updateRequestWithAuth({ stations }, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Assigns a list of station to the given user
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {string} stationID  - a list of station site ids
   * @return {Promise<string>}
  */
  async deleteStationsForUser(email: string, stations: StationSummary[]): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.del(apiName, getAPIPath(user.base, [user.stations, email.toLowerCase()]), updateRequestWithAuth({ stations }, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Adds a favourited article for the given user
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {string} articleID - the article id
   * @return {Promise<string>}
  */
  async saveUserBookmark(email: string, articleID: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const postData = updateRequestWithAuth({ article_id: articleID }, jwtToken);
      API.post(apiName, getAPIPath(user.base, [user.bookmark, email.toLowerCase()]), postData)
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Removes a favourited article for the given user
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {string} articleID - the article id
   * @return {Promise<string>}
  */
  async deleteUserBookmark(email: string, articleID: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.del(apiName, getAPIPath(user.base, [user.bookmark, email.toLowerCase()]), updateRequestWithAuth({ article_id: articleID }, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // ARTICLE
  /**
   * Retieved a list of articles the station has read
   * @author Kevin Parkinson
   * @param {string} stationID
   * @return {Promise<Article[]>}
   * @comments not currently implemented
  */
  async getStationArticleHistory(stationID: string): Promise<Article[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      /// TODO: modify query to get articles in station
      API.get(apiName, getAPIPath(article.base, [stationID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Retrieves whether an article has been acknowledged by the given station
   * @author Kevin Parkinson
   * @param {string} site_id - the station's site id
   * @param {string} articleID - self-eplanatory
   * @return {Promise<boolean>}
  */
  async isArticleAcknowledged(site_id: string, articleID: string): Promise<boolean> {
    const jwtToken = await getJWTAuthToken();
    const email = await getSessionEmail();
    return new Promise((resolve, reject) => {
      const postData = {
        article_id: articleID,
        email,
        site_id
      };
      API.post(apiName, getAPIPath(station.base, [station.getAcknowledge]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => {
          if (response === 'No article was found') resolve(false);
          resolve(response.data);
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // STATION
  /**
   * Retrieves a list of all stations
   * @author Kevin Parkinson
   * @return {Promise<StationSummary[]>}
  */
  async getStations(): Promise<StationSummary[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data.Items.map((st) => ({
          site_id: st.site_id,
          site_description: `${st.site_description} - ${st.address} ${st.city} ${st.province ? st.proince : ''}`
        }))))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Retrieves a station by the station's site id
   * @author Kevin Parkinson
   * @param {string} stationReference - the station's site id
   * @return {Promise<Station>}
  */
  async getStation(stationReference: string): Promise<Station> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [stationReference]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data.Items[0]))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations assigned to auser
   * @author Kevin Parkinson
   * @param {string} userName
   * @return {Promise<Station[]>}
  */
  async getStationsByEmail(email: string): Promise<Station[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [station.byemail, email.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations assigned to a Retail Operations Manager (ROM)
   * @author Kevin Parkinson
   * @param {string} userName
   * @return {Promise<Station[]>}
  */
  async getStationsByRomEmail(romEmail: string): Promise<Station[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(user.base, [romEmail.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Logs that a station has viewed an article for the current date and time
   * @author Kevin Parkinson
   * @param {string} articleID - self-explanatory
   * @param {string} siteID - the station's site id
   * @return {Promise<string>}
  */
  async saveViewArticle(articleID: string, siteID: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    const userEmail = await getSessionEmail();
    return new Promise((resolve, reject) => {
      const postData = {
        article_id: articleID,
        email: userEmail,
        site_id: siteID
      };
      API.post(apiName, getAPIPath(station.base, [station.history]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a list of stations assigned to a Territory Manager (TM)
   * @author Kevin Parkinson
   * @param {string} tmEmail - the email of the territory manager
   * @return {Promise<StationSummary[]>}
  */
  async getStationsByTMEmail(tmEmail: string): Promise<StationSummary[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [station.byemail, tmEmail.toLowerCase()]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data.Items.map((st) => ({ site_id: st.site_id, site_description: st.site_description }))))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Marks an article acknowledged by a station
   * @author Kevin Parkinson
   * @param {string} articleID - self-explanatory
   * @param {string} siteID - the station's site id
   * @return {Promise<string>}
  */
  async stationAcknolwedgeArticle(articleID: string, siteID: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    const userEmail = await getSessionEmail();
    return new Promise((resolve, reject) => {
      const postData = {
        article_id: articleID,
        email: userEmail,
        site_id: siteID,
        acknowledge: true
      };
      API.post(apiName, getAPIPath(station.base, [station.acknowledge]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // Dealers
  /**
   * Gets whether a user hasRDOC stations assigned to them or not
   * @author Kevin Parkinson
   * @param {string} email - the email of the user
   * @return {Promise<boolean>}
  */
  async doesUserHaveRDOCStations(email: string): Promise<boolean> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve) => {
      API.get(apiName, getAPIPath(station.base, [station.rdoc, email]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.count > 0 && response.data.length > 0))
        .catch((error) => {
          logHarmlessErrorAsinfo(serviceName, error, `it is likely that a user with email "${email}" doesn't exist or the system is down`);
          resolve(false);
        });
    });
  }

  /**
   * Gets a list of RDOC stations assigned to a specific user
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @return {Promise<string>}
  */
  async getRDOCStationsForUser(email: string): Promise<StationSummary[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve) => {
      API.get(apiName, getAPIPath(station.base, [station.rdoc, email]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.count === 0 || response.data.length === 0 ? [] : response.data as StationSummary[]))
        .catch((error) => {
          logHarmlessErrorAsinfo(serviceName, error, `it is likely that a user with "${email}" doesn't exist or the system is down`);
          resolve([]);
        });
    });
  }

  // Email
  /**
   * Sends a help email based on the user's input
   * @author Kevin Parkinson
   * @param {string} fromEmail - self-explanatory
   * @param {string} helpTopic - the topic t he user is contacting about
   * @param {string} detailRequest - the body of the user's message
   * @param {string} subject
   * @param {string} emailACopy - a flag specifying whether t he user wants a copy of the message or not
   * @return {Promise<string>}
  */
  async sendHelpEmail(fromEmail: string, helpTopic: string, detailRequest: string, subject: string, emailACopy: boolean): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const postData = {
        sent_from: fromEmail.toLowerCase(),
        sent_subject: `${subject} - ${helpTopic}`,
        sent_body: detailRequest,
        sent_copy: emailACopy
      };
      const request = updateRequestWithAuth(postData, jwtToken);
      API.post(apiName, getAPIPath(mail.base), request)
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Sends a an email of an article to share
   * @author Kevin Parkinson
   * @param {string} toEmail - self-explanatory
   * @param {string} messageBody - the body of the user's message
   * @param {string} subject - the subject of the user's message
   * @return {Promise<string>}
  */
  async sendArticleShareEmail(toEmail: string, messageBody: string, subject: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    const fromEmail = await getSessionEmail();
    return new Promise((resolve, reject) => {
      const postData = {
        sent_from: fromEmail,
        sent_to: toEmail.toLowerCase(),
        sent_subject: subject,
        sent_body: messageBody,
        is_html: true
      };
      const request = updateRequestWithAuth(postData, jwtToken);
      API.post(apiName, getAPIPath(mail.base, [mail.article]), request)
        .then((response) => resolve(response.message))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Sends a an email of an article to share
   * @author Kevin Parkinson
   * @param {string} fromEmail - self-explanatory
   * @param {string} messageBody - the body of the user's message
   * @param {EmailAttachment[]} attachments - a list of attachments in base 64 string format
   * @return {Promise<string>}
  */
  async sendServiceTicketEmail(fromEmail: string, messageBody: string, attachments: EmailAttachment[]): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const postData = {
        sent_from: fromEmail.toLowerCase(),
        sent_body: messageBody,
        is_html: true,
        attachments
      };

      API.post(apiName, getAPIPath(mail.base, [mail.serviceDesk]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // Activity Logging
  /**
   * Sends a help email based on the user's input
   * @author Kevin Parkinson
   * @param {UserActivityType} activityType - the type of activity to log
   * @param {any} otherMetadata - any other information you want to send in any format
   * @return {Promise<boolean>}
  */
  async logActivity(activityType: UserActivityType, otherMetadata?: any) {
    const email = await getSessionEmail();
    const jsonToken = await getJWTAuthToken();
    const state = store.getState();
    const stationId = state && state.userDetails && state.userDetails.selectedStation ? state.userDetails.selectedStation.site_id : null;
    const postData = {
      action_type: activityType,
      page: window.location.pathname,
      site_id: stationId,
      metadata: otherMetadata
    };
    API.put(apiName, getAPIPath(user.base, [user.logging, email]), updateRequestWithAuth(postData, jsonToken))
      .then((response) => logger.verbose(`logActivity logged: ${response}`));
  }

  /**
   * Sends a help email based on the user's input
   * @author Kevin Parkinson
   * @param {string} email - the user's email
   * @param {UserActivityType} activityType - the type of activity to log
   * @param {any} otherMetadata - any other information you want to send in any format
   * @return {Promise<boolean>}
  */
  async logActivityWithExplicitEmail(email: string, activityType: UserActivityType, otherMetadata?: any) {
    const jsonToken = await getJWTAuthToken();
    const state = store.getState();
    const stationId = state && state.userDetails && state.userDetails.selectedStation && state.userDetails.selectedStation.site_id;
    const postData = {
      action_type: activityType,
      page: window.location.pathname,
      siteId: stationId,
      metadata: otherMetadata
    };
    API.put(apiName, getAPIPath(user.base, [user.logging, email]), updateRequestWithAuth(postData, jsonToken))
      .then((response) => logger.verbose(`logActivity logged: ${response}`));
  }

  /**
   * Log an error
   * @author Kevin Parkinson
   * @param {string} error
   * @return void
  */
  async logErrorActivity(error: string) {
    await this.logActivity('error', { error });
  }

  /**
   * Log an error
   * @author Kevin Parkinson
   * @param {string} error
   * @return void
  */
  async logPageView() {
    await this.logActivity('pageview');
  }

  /**
   * Logs that the user exported file to excel
   * @author Kevin Parkinson
   * @param {string} fileName
   * @param {number} recordCount
   * @return void
  */
  // async logExportToExcelActivity(fileName: string, recordCount: number) {
  //   const fn = fileName.replaceAll(/'/g, ''); // replace single quote for json
  //   await this.logActivity('exporttoexcel', { fileName: fn, recordCount });
  // }

  /**
   * Logs that the user viewed a report
   * @author Kevin Parkinson
   * @param {string} reportName
   * @return void
  */
  async logSearchActivity(searchTerm: string) {
    const sch = searchTerm.replaceAll(/'/g, ''); // replace single quote for json
    await this.logActivity('search', { searchTerm: sch });
  }

  /**
   * Logs that the user downloaded a planogram
   * @author Kevin Parkinson
   * @param {string} fileName
   * @param {string} planogramName
   * @return void
  */
  async logDownloadPlanogramActivity(fileName: string, planogramName: string) {
    const fn = fileName.replaceAll(/'/g, ''); // replace single quote for json
    await this.logActivity('downloadplanogram', { fileName: fn, planogramName });
  }

  /**
   * Logs that the user downloaded a planogram
   * @author Kevin Parkinson
   * @param {string} reportName
   * @return void
  */
  async logDownloadPDFReportActivity(reportName: string) {
    await this.logActivity('downloadreportpdf', { reportName });
  }

  /**
   * Logs that the user downloaded a planogram
   * @author Kevin Parkinson
   * @param {string} siteId
   * @return void
  */
  // async logChangeStationActivity(siteId: string) {
  //   await this.logActivity('changestation', { siteId });
  // }

  /**
   * Logs that the user downloaded a planogram
   * @author Kevin Parkinson
   * @param {string} reportName
   * @return void
  */
  async logViewReportActivity(reportName: string) {
    await this.logActivity('viewreport', { report: reportName });
  }

  /**
   * Logs that the user downloaded a planogram
   * @author Kevin Parkinson
   * @param {string} articleName
   * @param {string} articleId
   * @return void
  */
  async logArticleViewActivity(articleName: string, articleId: string) {
    await this.logActivity('viewarticle', { articleName, articleId });
  }

  /**
   * Gets a report of user activity within the desired timeframe
   * @author Kevin Parkinson
   * @param {string} startDate
   * @param {string} endDate
   * @return {Promise<boolean>}
  */
  async getAllUserActivityInDateRange(email: string, startDate: string, endDate: string): Promise<UserActivityReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const queryParams = { startDate, endDate };
      API.get(apiName, getAPIPath(report.base, [report.logging, email]), gatewayGetRequestWithAuthAndParams(jwtToken, queryParams))
        .then((response) => resolve(response.data.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets a report of user activity within the desired timeframe
   * @author Kevin Parkinson
   * @param {UserActivityType} activityType - the type of activity to view
   * @param {string} startDate
   * @param {string} endDate
   * @return {Promise<boolean>}
  */
  async getUserActivityReportByType(activityType: UserActivityType, email: string, startDate: string, endDate: string): Promise<UserActivityReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const queryParams = { startDate, endDate };
      logger.verbose('url: ', getAPIPath(report.base, [report.logging, activityType]));
      API.get(apiName, getAPIPath(report.base, [report.logging, activityType, email]), gatewayGetRequestWithAuthAndParams(jwtToken, queryParams))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // Dealer
  /**
     * Gets site configuration  data for a given site
     * @author Kevin Parkinson
     * @param {string} siteID - self-explanatory
     * @return {Promise<SiteConfigurationResult>
  */
  async getSiteConfiguration(siteID: string): Promise<SiteConfigurationResult> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(dealer.base, [dealer.siteConfiguration, siteID]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets dealer commissiondata for a given site in a given language for the given time period
   * @author Kevin Parkinson
   * @param {string} siteID - self-explanatory
   * @param {string} language - self-explanatory
   * @param {string} startDate - self-explanatory
   * @param {string} endDate - self-explanatory
   * @return {Promise<CommissionReportResult[]]>}
  */
  async getDealerCommission(siteID: string, language: string, startDate: string, endDate: string): Promise<CommissionReportResult[]> {
    const jwtToken = await getJWTAuthToken();
    const url = `${getAPIPath(dealer.base, [dealer.commissionReport])}?SiteId=${siteID}&Language=${language}&StartDate=${standardURLFormatDate(startDate)}&EndDate=${standardURLFormatDate(endDate)}`;
    return new Promise((resolve, reject) => {
      API.get(apiName, url, getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets dealer commission report in PDF for a given site in a given language for the given time period
   * @author Kevin Parkinson
   * @param {string} siteID - self-explanatory
   * @param {string} language - self-explanatory
   * @param {string} startDate - self-explanatory
   * @param {string} endDate - self-explanatory
   * @return {Promise<CommissionReportResult[]]>}
  */
  async getDealerCommissionReportPDF(siteID: string, language: string, startDate: string, endDate: string): Promise<any> {
    const jwtToken = await getJWTAuthToken();
    const url = `${getAPIPath(dealer.base, [dealer.commissionReportPDF])}?SiteId=${siteID}&Language=${language}&StartDate=${standardURLFormatDate(startDate)}&EndDate=${standardURLFormatDate(endDate)}`;
    return new Promise((resolve, reject) => {
      API.get(apiName, url, getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // Mystery Shopper
  /**
   * Gets last 12 months of mystery shopper data for the specified stations
   * @author Kevin Parkinson
   * @param {string[]} stations - the list of stations you want to get mystery shopper data for
   * @return {Promise<MysteryShopperReportItem[]>}
  */
  async getMysteryShopperData(stations: string[]): Promise<MysteryShopperReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const queryStringParams = {
        startDate: toShortDate(subYears(new Date(), 1)),
        endDate: toShortDate(new Date()),
        stations: stations.join(',')
      };
      API.get(apiName, getAPIPath(report.mysteryShop), gatewayGetRequestWithAuthAndParams(jwtToken, queryStringParams))
        .then((msShopsResponse) => {
          let mysteryShopReportItems: MysteryShopperReportItem[] = [];
          const msShops = toMysteryShopReportItems(msShopsResponse.data);
          mysteryShopReportItems = _.concat(mysteryShopReportItems, msShops);
          API.get(apiName, getAPIPath(report.mysteryShop, [report.ageRestricted]), gatewayGetRequestWithAuthAndParams(jwtToken, queryStringParams))
            .then((arShopsResponse) => {
              const arShops = ageRestrictedToMysteryShopReportItems(arShopsResponse.data);
              mysteryShopReportItems = _.concat(mysteryShopReportItems, arShops);
              resolve(mysteryShopReportItems);
            })
            .catch((error) => standardGatewayRejectedPromise(reject, error));
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets last 12 months of mystery shopper data for the specified stations
   * @author Kevin Parkinson
   * @param {string[]} stations - the list of stations you want to get mystery shopper data for
   * @return {Promise<AgeRestrictedShopReportItem>}
  */
  async getAgeRestrictedMysteryShops(stations: string[]): Promise<AgeRestrictedShopReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const queryStringParams = {
        startDate: toShortDate(subYears(new Date(), 1)),
        endDate: toShortDate(new Date()),
        stations: stations.join(',')
      };
      API.get(apiName, getAPIPath(report.mysteryShop, [report.ageRestricted]), gatewayGetRequestWithAuthAndParams(jwtToken, queryStringParams))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets the PDF report for a specific mystery shop
   * @author Kevin Parkinson
   * @param {string} siteID -self-explanatory
   * @param {string} documentDate -self-explanatory
   * @return {Promise<MysteryShopperReportItem>}
  */
  async getMysteryShopPDF(siteID: string, documentDate: string): Promise<MysteryShopperReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const queryStringParams = {
        siteID,
        documentDate
      };
      API.get(apiName, getAPIPath(report.mysteryShopPDF), gatewayGetRequestWithAuthAndParams(jwtToken, queryStringParams))
        .then((response) => resolve(toMysteryShopReportItems(response.data)))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  // OTR Hub
  /**
   * Gets last 12 months of OTR promo data for the specified stations
   * @author Kevin Parkinson
   * @param {string} stationID - the list of stations you want to get OTR promo data for
   * @return {Promise<string>}
  */
  async getSelectedPromoPeriodForSite(stationID: string, otrCode: string): Promise<StationOTRSelection> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [station.otrHub, stationID, otrCode]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Gets last 12 months of OTR promo data for the specified stations
   * @author Kevin Parkinson
   * @param {string} stationID - the list of stations you want to get OTR promo data for
   * @return {Promise<string>}
  */
  async updateSelectedPromoPeriodForSite(stationID: string, promoOption: string, otrCode: string): Promise<StationOTRSelection> {
    const jwtToken = await getJWTAuthToken();
    const postData = { promo_code: promoOption };
    return new Promise((resolve, reject) => {
      API.post(apiName, getAPIPath(station.base, [station.otrHub, stationID, otrCode]), updateRequestWithAuth(postData, jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Get Current and Scheduled Fuel Price for the specified station
   * @author John Andaya
   * @param {string} siteID - the list of stations
   * @param {string} language - the list of stations
   * @return {Promise<FuelPrice[]>}
   */
  async getFuelPrices(siteID: string, language: string): Promise<FuelPrice[]> {
    const jwtToken = await getJWTAuthToken();
    const url = `${getAPIPath(dealer.base, [dealer.fuelPrice])}?SiteNo=${siteID}&Language=${language.toUpperCase()}`;
    return new Promise((resolve, reject) => {
      API.get(apiName, url, getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Get a list of all OTR Hub - ALD
   * @author John Andaya
   * @return {Promise<OTRHubReportResult[]>}
   */
  async getOTRHubs(): Promise<OTRHubReportResult[]> {
    const jwtToken = await getJWTAuthToken();
    const otrCode = 'ald';
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [station.downloadOTRHub, otrCode]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Get a list of all OTR Hub - CWD
   * @author Michael Lipski
   * @return {Promise<OTRHubReportResult[]>}
   */
  async getOTRHubsCWD(): Promise<OTRHubReportResult[]> {
    const jwtToken = await getJWTAuthToken();
    const otrCode = 'cwd';
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(station.base, [station.downloadOTRHub, otrCode]), getRequestWithAuth(jwtToken))
        .then((response) => resolve(response.data))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Searches all articles in Cententful using the gateway's extended search
   * @author Kevin Parkinson
   * @param {string} query - the user's search term
   * @param {string} language - the user's chosen language
   * @return {Promise<string>}
   */
  async cloudSearch(query: string, language: string): Promise<CloudSearchResults> {
    const jwtToken = await getJWTAuthToken();
    const encodedQuery = encodeURIComponent(query);
    const url = `${getAPIPath(search.base)}?query=${encodedQuery}&language=${language}`;
    return new Promise((resolve, reject) => {
      API.get(apiName, url, getRequestWithAuth(jwtToken))
        .then((response) => {
          const results = toCloudSearchResults(response, language);
          resolve(results);
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Searches all articles in Cententful using the gateway's extended search
   * @author Kevin Parkinson
   * @param {string} query - the user's search term
   * @param {string} brand
   * @param {string} classOfTrade
   * @param {string} region
   * @param {string} language - the user's chosen language
   * @return {Promise<string>}
   */
  async cloudSearchFiltered(query: string, siteID: string, brand: string, classOfTrade: string, region: string, isOtrDealer: boolean, language: string): Promise<CloudSearchResults> {
    const jwtToken = await getJWTAuthToken();
    const encodedQuery = encodeURIComponent(query);
    const url = `${getAPIPath(search.base)}?query=${encodedQuery}`;
    return new Promise((resolve, reject) => {
      API.get(apiName, url, getRequestWithAuth(jwtToken))
        .then((response) => {
          const filteredData = cloneDeep(response);
          const { results_articles: articles, results_planograms: planograms } = response;
          filteredData.results_articles = articles.filter((a) => filterSearchResult(siteID, brand, classOfTrade, region, isOtrDealer, a.business_unit, a.brands, a.regions, a.class_of_trade));
          filteredData.results_planograms = planograms.filter((p) => filterSearchResult(siteID, brand, classOfTrade, region, isOtrDealer, '', p.brands, p.regions, p.class_of_trade));
          const results = toCloudSearchResults(filteredData, language);
          resolve(results);
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
     * Sends a fuel runout report email based on the user's input
     * @author John Andaya
     * @param {string} bUNumber - self-explanatory
     * @param {string} emailAddress - the topic t he user is contacting about
     * @param {string} dateRunoutStart - the body of the user's message
     * @param {string} timeRunoutStart
     * @param {string} dateRunoutEnd
     * @param {string} timeRunoutEnd
     * @param {string} productOut
     * @param {string} territoryManager
     * @return {Promise<string>}
    */
  async sendFuelRunoutEmail(bUNumber: string,
    emailAddress: string,
    dateRunoutStart: string,
    timeRunoutStart: string,
    dateRunoutEnd: string,
    timeRunoutEnd: string,
    productOut: ProductOut[],
    territoryManager: string): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const postData = {
        bUNumber,
        emailAddress,
        dateRunoutStart: getDateInStandardYMDFormat(dateRunoutStart),
        timeRunoutStart: getDateInStandard12HourAMPMFormat(timeRunoutStart),
        dateRunoutEnd: getDateInStandardYMDFormat(dateRunoutEnd),
        timeRunoutEnd: getDateInStandard12HourAMPMFormat(timeRunoutEnd),
        productOut,
        territoryManager
      };
      const request = updateRequestWithAuth(postData, jwtToken);
      API.post(apiName, getAPIPath(mail.base, [mail.fuelRunout]), request)
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
     * Sends a fuel runout report email based on the user's input
     * @author John Andaya
     * @param {string} bUNumber - self-explanatory
     * @param {string} emailAddress - the topic t he user is contacting about
     * @param {string} dateRunoutStart - the body of the user's message
     * @param {string} timeRunoutStart
     * @param {string} dateRunoutEnd
     * @param {string} timeRunoutEnd
     * @param {string} productOut
     * @param {string} territoryManager
     * @return {Promise<string>}
    */
  async sendDowngradeRunoutEmail(fuelBrand: string,
    bUNumber: string,
    dateRunoutStart: string,
    timeRunoutStart: string,
    dateRunoutEnd: string,
    timeRunoutEnd: string,
    productOut: ProductOut[],
    didDowngradeOccur: string,
    numOfLitresDowngraded: string,
    comments: Comment[]): Promise<string> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      const postData = {
        fuelBrand,
        bUNumber,
        dateRunoutStart: getDateInStandardYMDFormat(dateRunoutStart),
        timeRunoutStart: getDateInStandard12HourAMPMFormat(timeRunoutStart),
        dateRunoutEnd: getDateInStandardYMDFormat(dateRunoutEnd),
        timeRunoutEnd: getDateInStandard12HourAMPMFormat(timeRunoutEnd),
        productOut,
        didDowngradeOccur,
        numOfLitresDowngraded,
        comments
      };
      const request = updateRequestWithAuth(postData, jwtToken);
      API.post(apiName, getAPIPath(mail.base, [mail.downGrade]), request)
        .then((response) => resolve(response))
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }

  /**
   * Get all users added by team member
   * @author Trevor Wong
   * @return {Promise<string>}
  */
  async getTeamManagedReport(): Promise<TeamManagedReportItem[]> {
    const jwtToken = await getJWTAuthToken();
    return new Promise((resolve, reject) => {
      API.get(apiName, getAPIPath(report.base, [report.teamManagedReport]), getRequestWithAuth(jwtToken))
        .then((response) => {
          resolve(response.results);
        })
        .catch((error) => standardGatewayRejectedPromise(reject, error));
    });
  }
}

export const gatewayService = new GatewayService();
