// Config
import { appConfig, contentfulConfig } from 'src/config/config';

// Constants
const {
  allBrandsEn,
  allBrandsFr,
  allRegionsEn,
  allRegionsFr
} = contentfulConfig.query.values;
const {
  otrDealer: otrDealerFlag
} = appConfig.contentful.classOfTrades;

/**
 * Checks to see whether a station's brand matches the brand array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {any[]} brands - the brands to search for a match
 * @return {boolean}
*/
export const brandMatch = (filter: string, brands: string[]): boolean => {
  if (!filter || !brands || brands.length === 0) return false;
  return brands.some((b) => b && ([filter, allBrandsEn, allBrandsFr].includes(b)));
};

/**
 * Checks to see whether a station's class of trade is found in the class of trade array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {string[]} classOfTrades - the class of trades to search for a match
 * @return {boolean}
 * @comments checks to see whether specific class of trade is found
*/
export const classOfTradeMatch = (filter: string, classOfTrades: string[]): boolean => {
  if (!filter || !classOfTrades || classOfTrades.length === 0) return false;
  return classOfTrades.some((c) => c && filter === c);
};

/**
 * Checks to see whether a station's region matches the region array
 * @author Kevin Parkinson
 * @param {string} filter
 * @param {string[]} regions - the regions to search for a match
 * @return {boolean}
*/
export const regionMatch = (filter: string, regions: string[]): boolean => {
  if (!filter || !regions || regions.length === 0) return false;
  return regions.some((r) => r && ([filter, allRegionsEn, allRegionsFr].includes(r)));
};

/**
 * checks to see whether the item should be returned based on whether t he station is an otr dealer
 * @author Kevin Parkinson
 * @param {boolean} isOTRDealer
 * @param {ClassOfTradeCollection} classOfTrades - the class of trades to search for a match
 * @return {boolean}
*/
export const otrDealerMatch = (classOfTrades: string[]): boolean => {
  if (!classOfTrades || classOfTrades.length === 0) return false;
  return classOfTrades.some((c) => c === otrDealerFlag);
};

/**
 * Checks to see whether a station's brand, region and class of trade matches the search result brand, region, class of trade and otr dealer
 * @author Kevin Parkinson
 * @param {string} siteID - the currently selected site id
 * @param {string} bFltr - the brand filter
 * @param {string} cotFltr- the class of trade filter
 * @param {string} rFltr- the region filter
 * @param {boolean} isOTRDealer- is the station an OTR dealer?
 * @param {string[]} brands - the earch result's list of brands
 * @param {string[]} regions - the earch result's list of regions
 * @param {string[]} classOfTrades - the earch result's list of classOfTrades
 * @return {boolean}
*/
export const filterSearchResult = (siteID: string,
  bFltr: string,
  cotFltr: string,
  rFltr: string,
  isOTRDealer: boolean,
  articleSpecificBu: string,
  brands: string[],
  regions: string[],
  classOfTrades:string []): boolean => {
  if (articleSpecificBu && articleSpecificBu.toUpperCase() !== 'NA' && articleSpecificBu !== siteID) return false;
  if (!brands || !regions || !classOfTrades || brands.length === 0 || regions.length === 0 || classOfTrades.length === 0) return false;
  if (isOTRDealer) return brandMatch(bFltr, brands) && regionMatch(rFltr, regions) && otrDealerMatch(classOfTrades);
  return brandMatch(bFltr, brands) && regionMatch(rFltr, regions) && classOfTradeMatch(cotFltr, classOfTrades);
};
