const helpTopics = {
  en: [
    'Marketing',
    'Merchandising',
    'General',
    'General Training Support',
    'LMS Training Support'
  ],
  fr: [
    'Commercialisation',
    'Marchandisage',
    'Générale',
    'Soutien à la formation générale',
    'Support de formation LMS'
  ]
};

export default helpTopics;
