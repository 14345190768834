export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const contentfulQueryConstants = {
  brand: 'generalBrands',
  classOfTrade: 'generalClassOfTrade',
  division: 'generalDivisions',
  generalLinkList: 'generalLinkList',
  generalListGroups: 'generalListGroups',
  externalLinks: 'External Links',
  form: 'generalForms',
  report: 'generalReports',
  helpTopic: 'generalHelpTopics',
  planogram: 'stationPortalPlanogram',
  stationPortalArticle: 'stationPortalArticle',
  stationPortalCategory: 'stationPortalCategory',
  stationPortalPlanogramCategory: 'stationPortalPlanogramCategory',
  stationPortalSubCategory: 'stationPortalSubCategory',
  stationPortalSubCategory2: 'stationPortalSubCategory2',
  region: 'generalRegions',
  tag: 'generalTags',
  contact: 'generalContacts',
  faq: 'stationPortalFaq',
  calendarEntry: 'generalCalendarEntry',
  articleCategories: {
    hseMomentsEN: 'HSE Moments',
    hseMomentsFR: 'Moments HSE',
    safetySecurityComplaince: 'Safety, Security and Compliance',
    carWash: 'Car Wash',
    otrDealerEN: 'OTR Dealer',
    otrDealerFR: 'Concessionnaire OTR',
    termsOfUse: 'Terms of Use'
  },
  values: {
    True: 'true',
    False: 'false'
  }
};
