// Contentful
import { Entry, EntryCollection } from 'contentful';

// Custom types
import ContentfulArticle from 'src/types/contentful/contentfulArticle';
import ContentfulArticleSummary from 'src/types/contentful/contentfulArticleSummary';
import ContentfulArticleSummaryWithFilters from 'src/types/contentful/contentfulArticleSummaryWithFilters';
import ContentfulAnnouncementWithFilters from 'src/types/contentful/contentfulAnnouncementWithFilters';
import ContentfulCriticalUpdateWithFilters from 'src/types/contentful/contentfulCriticalUpdateWithFilters';
import ContentfulLatestUpdateWithFilters from 'src/types/contentful/contentfulLatestUpdateWithFilters';
import ContentfulHSEMoment from 'src/types/contentful/contentfulHSEMoment';
import Article from 'src/types/article';
import ArticleSummary from 'src/types/articleSummary';
import Announcement from 'src/types/announcement';
import CriticalUpdate from 'src/types/criticalUpdate';
import LatestUpdate from 'src/types/latestUpdate';
import HealthAndSafetyMoment from 'src/types/healthAndSafetyMoment';

// Data converters
import {
  simplifyContentfulBrandsAny,
  simplifyContentfulRegionsAny,
  simplifyContentfulTagsAny,
  simplifyContentfulAttachmentsAny,
  simplifyContentfulClassOfTrades
} from 'src/converters/contentful';

/**
 * Converts an article in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticle>} entry - the Contentful article to convert
 * @return {Article}
*/
export const toArticle = (entry: Entry<ContentfulArticle>): Article => {
  const { fields, sys } = entry;
  const { title, article, needsAcknowledgment, announcement, criticalUpdate, brands, regions, attachments, classOfTrade, tags } = fields;
  const articleModel: Article = {
    id: sys && sys.id ? sys.id : '',
    title: title ?? '',
    createdDate: sys.createdAt,
    updatedDate: sys.updatedAt,
    heroTitle: fields.hero && fields.hero.fields && fields.hero.fields.title ? fields.hero.fields.title : '',
    hero: fields.hero && fields.hero.fields && fields.hero.fields.file && fields.hero.fields.file.url ? fields.hero.fields.file.url : '',
    summary: fields.summary,
    category: fields.category && fields.category.fields && fields.category.fields.category,
    subCategory: fields.subCategory && fields.subCategory.fields && fields.subCategory.fields.subCategory,
    subCategory2: fields.subCategory2 && fields.subCategory2.fields && fields.subCategory2.fields.subCategory2 ? fields.subCategory2.fields.subCategory2 : '',
    article,
    specificBu: fields.specificBu,
    needsAcknowledgment: needsAcknowledgment ?? false,
    announcement: announcement ?? false,
    criticalUpdate: criticalUpdate ?? false,
    // TODO: fix uses of Any if possible
    attachments: attachments ? simplifyContentfulAttachmentsAny(attachments) : [],
    tags: tags ? simplifyContentfulTagsAny(tags) : [],
    brands: brands ? simplifyContentfulBrandsAny(brands) : [],
    regions: regions ? simplifyContentfulRegionsAny(regions) : [],
    classOfTrade: classOfTrade ? simplifyContentfulClassOfTrades(classOfTrade) : [],
    languages: fields.languages,
  };
  return articleModel;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummary>} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/
export const toArticleSummary = (entry: Entry<ContentfulArticleSummary>): ArticleSummary => {
  const { fields, sys } = entry;
  const articleSummaryModel: ArticleSummary = {
    id: sys.id,
    title: fields.title ?? '',
    updatedDate: sys.updatedAt ?? '',
    summary: fields.summary ?? '',
    category: fields.category && fields.category.fields && fields.category.fields.category ? fields.category.fields.category : '',
    subCategory: fields.subCategory && fields.subCategory.fields && fields.subCategory.fields.subCategory ? fields.subCategory.fields.subCategory : '',
    subCategory2: fields.subCategory2 && fields.subCategory2.fields && fields.subCategory2.fields.subCategory2 ? fields.subCategory2.fields.subCategory2 : '',
    tags: fields.tags ? simplifyContentfulTagsAny(fields.tags) : [],
  };
  return articleSummaryModel;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummary>} entry - the Contentful article summary to convert
 * @return {ArticleSummary}
*/
export const taggedArticleToArticleSummary = (article: any): ArticleSummary => {
  const articleSummaryModel: ArticleSummary = {
    id: article.sys.id,
    title: article.title ?? '',
    updatedDate: article.sys.publishedAt ?? '',
    summary: article.summary ?? '',
    category: article.category && article.category.category ? article.category.category : '',
    subCategory: article.subCategory && article.subCategory.subCategory ? article.subCategory.subCategory : '',
    subCategory2: article.subCategory2 && article.subCategory2.subCategory2 ? article.subCategory2.subCategory2 : '',
    tags: article.tags ? simplifyContentfulTagsAny(article.tags) : [],
  };
  return articleSummaryModel;
};

/**
 * Converts an 'article summary' in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummaryWithFilters>} entry - the Contentful article (including brand, region and class of trade filters) summary to convert
 * @return {ArticleSummary}
*/
export const toArticleSummaryFiltered = (entry: Entry<ContentfulArticleSummaryWithFilters>): ArticleSummary => {
  const { fields, sys } = entry;
  const articleSummaryModel: ArticleSummary = {
    id: sys && sys.id ? sys.id : '',
    title: fields.title ?? '',
    updatedDate: sys.updatedAt ?? '',
    summary: fields.summary ?? '',
    category: fields.category && fields.category.fields && fields.category.fields.category ? fields.category.fields.category : '',
    subCategory: fields.subCategory && fields.subCategory.fields && fields.subCategory.fields.subCategory ? fields.subCategory.fields.subCategory : '',
    subCategory2: fields.subCategory2 && fields.subCategory2.fields && fields.subCategory2.fields.subCategory2 ? fields.subCategory2.fields.subCategory2 : '',
    tags: []
  };
  return articleSummaryModel;
};

/**
 * Converts an announcement in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulAnnouncementWithFilters>} entry - the Contentful announcement (including brand, region and class of trade filters) summary to convert
 * @return {Announcement}
*/
export const toAnnouncementFiltered = (entry: Entry<ContentfulAnnouncementWithFilters>): Announcement => {
  const { fields, sys } = entry;
  return {
    id: sys && sys.id ? sys.id : '',
    title: fields.title ?? '',
    summary: fields.summary ?? '',
    heroTitle: fields.hero && fields.hero.fields && fields.hero.fields.title ? fields.hero.fields.title : '',
    heroUrl: fields.hero && fields.hero.fields && fields.hero.fields.file && fields.hero.fields.file.url ? fields.hero.fields.file.url : ''
  };
};

/**
 * Converts a critical update in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulCriticalUpdateWithFilters>} entry - the Contentful critical update (including brand, region and class of trade filters) summary to convert
 * @return {CriticalUpdate}
*/
export const toCriticalUpdateFiltered = (entry: Entry<ContentfulCriticalUpdateWithFilters>): CriticalUpdate => {
  const { fields, sys } = entry;
  return {
    id: sys && sys.id ? sys.id : '',
    title: fields.title ?? '',
    summary: fields.summary ?? '',
    specificBu: fields.specificBu ?? null
  };
};

/**
 * Converts an update in last 7 days in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulLatestUpdateWithFilters>} entry - the Contentful update in last 7 days (including brand, region and class of trade filters) summary to convert
 * @return {LatestUpdate}
*/
export const toLatestUpdateFiltered = (entry: Entry<ContentfulLatestUpdateWithFilters>): LatestUpdate => {
  const { fields, sys } = entry;
  return {
    id: sys && sys.id ? sys.id : '',
    title: fields.title ?? '',
    summary: fields.summary ?? '',
    updatedDate: sys && sys.updatedAt ? sys.updatedAt : '',
    updatedTimestamp: sys && sys.updatedAt ? Date.parse(sys.updatedAt) : 0
  };
};

/**
 * Converts a list of articles in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulArticle>} entries - the Contentful articles to convert
 * @return {Article[]}
*/
export const toArticles = (entries: EntryCollection<ContentfulArticle>): Article[] => entries.items.map((as) => toArticle(as));

/**
 * Converts a list of article summaries in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulArticleSummary>} entries - the Contentful articles to convert
 * @return {ArticleSummary[]}
*/
export const toArticleSummaries = (entries: Entry<ContentfulArticleSummary>[]): ArticleSummary[] => entries.map((as) => toArticleSummary(as));

/**
 * Converts a list of article summaries in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulArticleSummaryWithFilters>[]} entries - the Contentful article summaries (including brand, region and class of trade filters) to convert
 * @return {ArticleSummary[]}
*/
export const toArticleSummariesFiltered = (entries: Entry<ContentfulArticleSummaryWithFilters>[]): ArticleSummary[] => entries.map((as) => toArticleSummaryFiltered(as));

/**
 * Converts a list of announcements in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulAnnouncementWithFilters>} entries - the Contentful announcements (including brand, region and class of trade filters) to convert
 * @return {Announcement[]}
*/
export const toAnnouncements = (entries: Entry<ContentfulAnnouncementWithFilters>[]): Announcement[] => entries.map((a) => toAnnouncementFiltered(a));

/**
 * Converts a list of critical updates in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulCriticalUpdateWithFilters>[]} entries - the Contentful critical updates (including brand, region and class of trade filters) to convert
 * @return {CriticalUpdate[]}
*/
export const toCriticalUpdates = (entries: Entry<ContentfulCriticalUpdateWithFilters>[]): CriticalUpdate[] => entries.map((c) => toCriticalUpdateFiltered(c));

/**
 * Converts a list of updates in the last 7 days in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulLatestUpdateWithFilters>[]} entries - the Contentful updates in the last 7 days (including brand, region and class of trade filters) to convert
 * @return {LatestUpdate[]}
*/
export const toLatestUpdates = (entries: Entry<ContentfulLatestUpdateWithFilters>[]): LatestUpdate[] => entries.map((u) => toLatestUpdateFiltered(u));

/**
 * Converts a critical update in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {Entry<ContentfulHSEMoment>} entry - the Contentful critical update (including brand, region and class of trade filters) summary to convert
 * @return {CriticalUpdate}
*/
export const toHSEMoment = (entry: Entry<ContentfulHSEMoment>): HealthAndSafetyMoment => {
  const { fields, sys } = entry;
  return {
    articleID: sys && sys.id ? sys.id : '',
    articleTitle: fields.title ?? '',
    articleSummary: fields.summary ?? '',
    hero: fields.hero && fields.hero.fields && fields.hero.fields.file && fields.hero.fields.file.url ? fields.hero.fields.file.url : ''
  };
};
/**
 * Converts a list of updates in the last 7 days in Contentful format to a much more lean and flat structure
 * @author Kevin Parkinson
 * @param {EntryCollection<ContentfulHSEMoment>} entries - the Contentful updates in the last 7 days (including brand, region and class of trade filters) to convert
 * @return {LatestUpdate[]}
*/
export const toHSEMoments = (entries: Entry<ContentfulHSEMoment>[]): HealthAndSafetyMoment[] => entries.map((e) => toHSEMoment(e));
